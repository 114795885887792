import * as React from 'react'
import { shape, string } from 'prop-types'
import Legal from '@/templates/Legal'
import * as restAPI from "../utils/endpoints";

function CookiePolicy({ location }) {
	return (
		<Legal
			pathname={location.pathname}
			type="cookie"
			title="Our Cookie Policy"
			url={restAPI.GET_COOKIE_POLICY}
		/>
	)
}

CookiePolicy.propTypes = {
	location: shape({
		pathname: string
	}).isRequired
}

export default CookiePolicy
